import { useTranslation } from "react-i18next"

export const Guide = () => {
  const { t } = useTranslation()

  return (
    <div className='text-donut p-4'>
      <h1 className='my-4'>{t('使用方法・マニュアル')}</h1>

      <p>
        {t('使用マニュアルは以下からダウンロードできます。')}
      </p>
      <p>(PDF, 2.31MB 2023/09/19)</p>

      <a href="/GPTech_操作マニュアル.pdf">
        <b>
          {t('ダウンロード')}
        </b>
      </a>
    </div>
  )
}
