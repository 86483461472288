import './SttDictionaries.css'

import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { TargetWord } from "./component/TargetWord";
import React, { useEffect, useRef, useState } from "react";
import {
  createUserSttDictionaries,
  deleteUserSttDictionaries,
  getSystemSttDictionaries,
  getUserSttDictionaries,
  updateUserSttDictionaries,
  SttDictionariesFirebaseData
} from "../../../api/MeetingFirebaseApi";
import UserInfo from "../../../store/UserInfo";

const separatorString: string = ' ';

export const SttDictionaries = observer(() => {
  const { t } = useTranslation()
  const [keyword, setKeyword] = useState('');
  const [data, setData] = useState<SttDictionariesFirebaseData[]>([]);

  const defaultRef = useRef<SttDictionariesFirebaseData[]>([]);
  const addTargetWordRef = useRef<HTMLInputElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const existSystemSttDictionaries = async (data: SttDictionariesFirebaseData[]) => {
    const systemSttDictionaries = await getSystemSttDictionaries();
    const systems: string[] = [];
    for (const systemTargetWord of systemSttDictionaries) {
      systems.push(systemTargetWord.targetWord);
      if (systemTargetWord.sourceWords) {
        systems.push(...systemTargetWord.sourceWords);
      }
    }
    for (const datum of data) {
      if (systems.includes(datum.targetWord)) {
        return true;
      }
      if (datum.sourceWords) {
        for (const sourceWord of datum.sourceWords){
          if (systems.includes(sourceWord)) {
            return true;
          }
        }
      }
    }
    return false;
  }

  const existUserSttDictionaries = async (data: SttDictionariesFirebaseData[]) => {
    const users: string[] = [];
    for (const datum of data) {
      users.push(datum.targetWord);
      if (datum.sourceWords) {
        users.push(...datum.sourceWords);
      }
    }
    const set = new Set(users);
    if (set.size !== users.length) {
      return true;
    }
    return false;
  }

  const checkValidSttDictionaries = async (data: SttDictionariesFirebaseData[]) => {
    if(await existSystemSttDictionaries(data)){
      alert(t('管理者によって設定されています。この言葉を使用することはできません。'));
      return false;
    }
    if(await existUserSttDictionaries(data)){
      alert(t('重複があります。同じ言葉を使用することはできません。'));
      return false;
    }
    return true;
  }

  const onClickUploadButton = () => {
    fileInputRef.current?.click();
  };

  const onChangeFile = async () => {
    if(!fileInputRef.current || !fileInputRef.current.files || fileInputRef.current.files.length === 0){
      return false;
    }
    const file = fileInputRef.current.files[0];
    if (file.name.split(".").at(-1) !== "json") {
      alert(t('現在、ファイルアップロード機能はJSONファイル（.json）のみ対応しています'));
      return false;
    }
    const text: string = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsText(file);
    });
    let json: {} = {};
    try {
      json = JSON.parse(text);
    }catch (e){
      alert(t('JSONファイルの内容が不正です'));
      return false;
    }
    const data: SttDictionariesFirebaseData[] = [];
    for (const [index, val] of Object.entries(json)) {
      // @ts-ignore
      if(!val.targetWord || val.targetWord === '' || !val.sourceWords || val.sourceWords.length === 0){
        alert(t('JSONファイルの内容が不正です'));
        return false;
      }
      // @ts-ignore
      const datum = {targetWord: val.targetWord, sourceWords: val.sourceWords };

      const is_valid = await checkValidSttDictionaries([datum]);
      if (!is_valid) {
        alert(`${index + 1}${t('行目: エラーが発生しました。テキストファイルの内容を確認してください。')}`);
        return false;
      }
      data.push(datum);
    }
    for (const datum of data) {
      await createUserSttDictionaries(UserInfo.id, datum);
    }
    const sttDictionaries = await getUserSttDictionaries(UserInfo.id);
    setData(sttDictionaries);
    alert(t('取り込みが完了しました'));
    return true;
  };

  const onClickAddTargetWord = async () => {
    if (!addTargetWordRef.current) {
      return;
    }

    const add: SttDictionariesFirebaseData = {
      targetWord: addTargetWordRef.current.value
    };
    const merge = [...data, add];
    const is_valid = await checkValidSttDictionaries(merge);
    if (!is_valid) {
      return;
    }
    await createUserSttDictionaries(UserInfo.id, add);
    const sttDictionaries = await getUserSttDictionaries(UserInfo.id);
    setData(sttDictionaries);
    addTargetWordRef.current.value = '';
  }

  const onUpdate = async (update: SttDictionariesFirebaseData): Promise<boolean> => {
    const merge = [...data];
    const index = merge.findIndex(w => w.id === update.id);
    if(index === -1){
      return false;
    }
    merge[index] = update;
    const is_valid = await checkValidSttDictionaries(merge);
    if (!is_valid) {
      return false;
    }
    await updateUserSttDictionaries(UserInfo.id, update);
    const sttDictionaries = await getUserSttDictionaries(UserInfo.id);
    setData(sttDictionaries);
    return true;
  }

  const onDelete = async (id: string): Promise<boolean> => {
    await deleteUserSttDictionaries(UserInfo.id, id);
    const sttDictionaries = await getUserSttDictionaries(UserInfo.id);
    setData(sttDictionaries);
    return true;
  }

  const onChangeSearchKeyword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.currentTarget.value);
  }

  useEffect(() => {
    getUserSttDictionaries(UserInfo.id).then((sttDictionaries) => {
      setData(sttDictionaries);
      defaultRef.current = sttDictionaries;
    });
  }, []);

  useEffect(() => {
    getUserSttDictionaries(UserInfo.id).then((sttDictionaries) => {
      defaultRef.current = sttDictionaries;
      if(keyword === ''){
        setData(sttDictionaries);
        return;
      }
      const keywords = keyword.split(separatorString);
      const filtered: SttDictionariesFirebaseData[] = [];
      for (const datum of defaultRef.current) {
        const matchesKeyword = keywords.every(keyword =>
            datum.targetWord.includes(keyword) || (datum.sourceWords && datum.sourceWords?.some((sourceWord) => sourceWord.includes(keyword)))
        );
        if (matchesKeyword) {
          filtered.push(datum);
        }
      }
      setData(filtered);
    });
  }, [keyword]);

  return (
      <div className='container p-4 p-md-4'>
        <div className='row mb-3'>
          <div className='col-auto h1 text-primary mb-0'>
            <b>{t('辞書登録')}</b>
          </div>
          <div className='col text-donut'>
            {t('内容を入力したら、項目ごとに「追加」「変更」ボタンをクリックしてください')}
          </div>
        </div>
        <div className="row mb-3 g-3">
          <div className="col-2">
            <button type="button"
                    className="btn btn-purple text-light w-100"
                    onClick={onClickUploadButton}
            >{t('一括取込')}</button>
            <input ref={fileInputRef}
                   onChange={onChangeFile}
                   className="d-none"
                   accept='application/json'
                   multiple={false}
                   type="file" />
          </div>
          <div className="col-10">
            <div className="input-group">
              <div className="input-group-text"><i className="bi bi-search"></i></div>
              <input type="text"
                     className="form-control"
                     onChange={onChangeSearchKeyword}
                     value={keyword}
                     placeholder={t('登録済の辞書を検索...')}/>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className="accordion" id="accordionWords">
            {data.map((datum) => (
                <TargetWord key={datum.id}
                            id={datum.id}
                            targetWord={datum.targetWord}
                            sourceWords={datum.sourceWords}
                            onUpdate={onUpdate}
                            onDelete={onDelete} />
            ))}
            <div className="accordion-item">
              <h2 className="accordion-header">
                <div className="accordion-button collapsed accordion-add-button">
                  <div className="row g-3 w-100 pe-2">
                    <div className="col-10">
                      <input className="form-control"
                             type="text"
                             ref={addTargetWordRef}
                             placeholder={t('辞書追加...')} />
                    </div>
                    <div className="col-2">
                      <button type="button"
                              className="btn btn-primary w-100"
                              onClick={onClickAddTargetWord}
                      >{t('追加')}</button>
                    </div>
                  </div>
                </div>
              </h2>
            </div>
          </div>
        </div>
      </div>
  )
})
