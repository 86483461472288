import axios, { type AxiosResponse } from 'axios'
import { sentryLog } from "../util/sentry";

const Api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
})

export const setToken = (token?: string) => {
  if (token) {
    // Api.defaults.headers = {
    //   ...Api.defaults.headers,
    //   authorization: token,
    // }
    Api.defaults.headers.authorization = token
  } else {
    delete Api.defaults.headers.authorization
  }
}

export default Api

export const ApiWrapper = async (request: Promise<AxiosResponse>) => {
  let response, error
  try {
    let resRaw = await request
    if (resRaw.data?.success) {
      response = resRaw.data
    } else {
      throw resRaw.data?.message || 'エラー'
    }
  } catch (err: any) {
    // log to sentry
    sentryLog(err)
    error = err
  }

  return [response, error]
}
