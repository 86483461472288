import React, { useEffect, useRef, useState } from "react";
import { SourceWords } from "./SourceWords";
import { useTranslation } from "react-i18next";
import { SttDictionariesFirebaseData } from "../../../../api/MeetingFirebaseApi";

export const TargetWord = ({targetWord, id, sourceWords, onUpdate, onDelete}: { targetWord: string, id?: string, sourceWords?: string[], onUpdate: (update: SttDictionariesFirebaseData) => Promise<boolean>, onDelete: (id: string) => Promise<boolean> }) => {
    const {t} = useTranslation()

    const [isOpen, setIsOpen] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [targetWordValue, setTargetWordValue] = useState(targetWord);

    const containerRef = useRef<HTMLDivElement>(null);
    const collapseRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLDivElement>(null);
    const targetWordInputRef = useRef<HTMLInputElement>(null);
    const addSourceWordsRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const containerElement = containerRef.current;
        if(!containerElement){
            return;
        }
        const handleFocusOut = (event: FocusEvent) => {
            if (!containerRef.current?.contains(event.relatedTarget as Node)) {
                setIsActive(false)
            }
        }
        containerElement.addEventListener('focusout', handleFocusOut);

        return () => {
            containerElement.removeEventListener('focusout', handleFocusOut);
        };
    }, []);

    useEffect(() => {
        const bsCollapse = new (window as any).bootstrap.Collapse(collapseRef.current, {
            toggle: false,
        });

        const handleShow = () => {
            setIsOpen(true);
        }
        const handleHide = () => {
            setIsOpen(false);
        }

        collapseRef.current?.addEventListener('show.bs.collapse', handleShow);
        collapseRef.current?.addEventListener('hide.bs.collapse', handleHide);

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            collapseRef.current?.removeEventListener('show.bs.collapse', handleShow);
            // eslint-disable-next-line react-hooks/exhaustive-deps
            collapseRef.current?.removeEventListener('hide.bs.collapse', handleHide);
            bsCollapse.dispose();
        };
    }, []);

    useEffect(() => {
        let observer: MutationObserver;
        if(collapseRef.current){
            // MutationObserver を使ってクラスの変更を監視
            observer = new MutationObserver(mutations => {
                mutations.forEach(mutation => {
                    if (mutation.attributeName === 'class') {
                        const target = mutation.target as HTMLElement;
                        const classList = target.classList;
                        if (classList.contains('show')) {
                            setIsOpen(true);
                        } else {
                            setIsOpen(false);
                        }
                    }
                });
            });

            // 監視の開始
            observer.observe(collapseRef.current, { attributes: true });
        }

        // クリーンアップ関数で監視を停止
        return () => {
            observer?.disconnect();
        };
    }, []);

    const toggleAccordion = () => {
        const bsCollapse = new (window as any).bootstrap.Collapse(collapseRef.current, {
            toggle: true,
        });

        if (!isOpen) {
            bsCollapse.show();
        } else {
            bsCollapse.hide();
            setIsActive(false);
        }
    };

    const onChangeTargetWordInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTargetWordValue(event.currentTarget.value);
    };

    const onFocusTargetWordInput = () => {
        setIsActive(true)
    };

    const onClickChangeTargetWord = async () => {
        if(!targetWordInputRef.current){
            return;
        }
        const update: SttDictionariesFirebaseData = {
            id: id,
            targetWord: targetWordInputRef.current.value,
            sourceWords: sourceWords ?? []
        }
        await onUpdate(update);
        setIsActive(false);
    }

    const onClickDeleteTargetWord = async () => {
        if(!id){
            return;
        }
        await onDelete(id);
    }

    const onClickAddSourceWords = async () => {
        if(!addSourceWordsRef.current){
            return;
        }
        const updateSourceWords: string[] = [];
        if(sourceWords){
            updateSourceWords.push(...sourceWords);
        }
        updateSourceWords.push(addSourceWordsRef.current.value);
        const update: SttDictionariesFirebaseData = {
            id: id,
            targetWord: targetWord,
            sourceWords: updateSourceWords
        }
        if(await onUpdate(update)){
            addSourceWordsRef.current.value = '';
        }
    }

    const onUpdateSourceWords = async (index: number, sourceWord: string) => {
        const updateSourceWords: string[] = [];
        if(sourceWords){
            updateSourceWords.push(...sourceWords);
        }
        if(updateSourceWords[index]){
            updateSourceWords[index] = sourceWord;
        }
        const update: SttDictionariesFirebaseData = {
            id: id,
            targetWord: targetWord,
            sourceWords: updateSourceWords
        }
        return await onUpdate(update);
    }

    const onDeleteSourceWords = async (index: number) => {
        const updateSourceWords: string[] = [];
        if(sourceWords){
            updateSourceWords.push(...sourceWords);
        }
        if(updateSourceWords[index]){
            updateSourceWords.splice(index, 1);
        }
        const update: SttDictionariesFirebaseData = {
            id: id,
            targetWord: targetWord,
            sourceWords: updateSourceWords
        }
        return await onUpdate(update);
    }

    return (
        <div className="accordion-item">
            <h2 className="accordion-header"
                ref={containerRef}
            >
                <div
                    className="accordion-button collapsed"
                    ref={buttonRef}
                    onClick={toggleAccordion}
                    data-bs-toggle="collapse"
                    data-bs-target={`#accordionWords-${id}`}
                    aria-controls={`accordionWords-${id}`}
                >
                    <div className="row g-3 w-100 pe-2">
                        <div className="col-10">
                            <input ref={targetWordInputRef}
                                   className="form-control"
                                   type="text"
                                   value={targetWordValue}
                                   onFocus={onFocusTargetWordInput}
                                   onChange={onChangeTargetWordInput}
                            />
                        </div>
                        <div className="col-1">
                            <button type="button"
                                    className={`btn btn-success w-100 ${isActive ? '' : 'visually-hidden'}`}
                                    onClick={onClickChangeTargetWord}
                            >{t('変更')}</button>
                        </div>
                        <div className="col-1">
                            <button type="button"
                                    className={`btn btn-danger w-100 ${isActive ? '' : 'visually-hidden'}`}
                                    onClick={onClickDeleteTargetWord}
                            >{t('削除')}</button>
                        </div>
                    </div>
                </div>
            </h2>
            <div
                ref={collapseRef}
                className="accordion-collapse collapse"
                id={`accordionWords-${id}`}
                data-bs-parent="#accordionWords">
                <div className="accordion-body p-2" style={{paddingLeft: '40px'}}>
                    <ul className="list-group list-group-flush">
                        {sourceWords && sourceWords.map((sourceWord, index) => (
                            <SourceWords key={index} index={index} sourceWord={sourceWord} onUpdate={onUpdateSourceWords} onDelete={onDeleteSourceWords} />
                        ))}
                        <li className="list-group-item list-group-item-add">
                            <div className="row g-3 w-100 pe-2">
                                <div className="col-10">
                                    <input ref={addSourceWordsRef}
                                           className="form-control form-control-sm"
                                           type="text"
                                           placeholder={t('置換する言葉を追加...')} />
                                </div>
                                <div className="col-2">
                                    <button type="button"
                                            className="btn btn-sm btn-primary w-100"
                                            onClick={onClickAddSourceWords}
                                    >{t('追加')}</button>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
