import './IncreaseMemberLimit.css'

import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Api from '../../../api/Api'
import { GET_MEMBER_LIMIT_PRICES } from '../../../constant/Endpoints'

export type OptionPrice = {
  memCount: number
  price: number
}

type OptionRowProps = {
  price: OptionPrice
  onClick?: (price: OptionPrice) => void
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'JPY',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
})

const OptionRow = ({ price, onClick }: OptionRowProps) => {
  const { t } = useTranslation()

  const handleClick = useCallback(() => {
    onClick?.(price)
  }, [onClick, price])

  return (
    <div className='row align-items-center py-1 price-row'>
      <div className='col-auto'>
        <div className='btn btn-sm btn-primary rounded-pill px-3' onClick={handleClick}>
          {t('購入')}
        </div>
      </div>
      <div className='col-4' >
        {t('追加アカウント', { count: price.memCount })}
      </div>
      <div className='col-auto'>
        <b>
          {formatter.format(price.price)}
        </b>
      </div>
    </div>
  )
}

export const IncreaseMemberLimit = ({
  onItemClick }: { onItemClick?: (price: OptionPrice) => void },
) => {
  const { t } = useTranslation()

  const [prices, setPrices] = useState<OptionPrice[]>([])

  // fetch price options from server
  useEffect(() => {
    Api.get(GET_MEMBER_LIMIT_PRICES).then((response) => {
      const priceList: OptionPrice[] = response.data.prices || []
      if (priceList.length) {
        setPrices(priceList)
      } else {
        throw new Error('Empty price list')
      }
    }).catch((error) => {
      console.log('IncreaseMemberLimit form: Error fetching price list', error.message)
    })
  }, [t])

  return (
    <>
      {prices.length ? prices.map((p) => (
        <OptionRow key={p.memCount} price={p} onClick={onItemClick} />
      )) : t('処理中…')}
    </>
  )
}
