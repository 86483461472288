import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
// import reportWebVitals from './reportWebVitals'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/dist/border.css'

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import EN from "./locales/en/translation.json"
import JA from "./locales/ja/translation.json"
import ZH_CN from "./locales/zh-CN/translation.json"
import ZH_TW from "./locales/zh-TW/translation.json"
import ID from "./locales/id/translation.json"

import { setupSentry } from './util/sentry'

// Initialize Sentry.
setupSentry()

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'ja',
    lng: 'ja',

    resources: {
      "en": {
        translation: EN,
      },
      "ja": {
        translation: JA,
      },
      "zh-CN": {
        translation: ZH_CN,
      },
      "zh-TW": {
        translation: ZH_TW,
      },
      "id": {
        translation: ID,
      },
    },

    interpolation: {
      escapeValue: false,
    }
  })

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <React.StrictMode>
    <Suspense fallback="Loading">
      <App />
    </Suspense>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
