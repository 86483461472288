import './LoginPage.css'

import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useCountDown } from '../../hook/useCountDown'
import UserInfo, { type TPlan } from '../../store/UserInfo'
import Api from '../../api/Api'
import { GET_RESELLER_INFO, GET_RESELLER_PLANS, LIST_OF_AVAILABLE_PLANS } from '../../constant/Endpoints'
import { PlanSelection } from './PlanSelection'
import { sentryLog } from "../../util/sentry";
import { _parseQueryParam } from '../../util/Util'

type GPTechReseller = {
  id: string
  code: string
  name: string
}

const SIGN_IN_MODE = {
  SIGN_IN: 's',
  FORGOT_PASSWORD: 'f',
  REGISTER_1: 'r1',
  REGISTER_2: 'r2', // step 2 which lets user to choose a plan
}

const isAcceptablePassword = (pwd: string) => {
  return !!pwd.trim()
}

const LoginPage = () => {
  const { t, i18n } = useTranslation()
  const language = i18n.language

  const SIGN_IN_ERROR = useMemo(() => ({
    WEAK_PASSWORD: t('より強いパスワードが必要'), //'パスワードは６桁以上で、大文字、小文字、数字を含む必要があります',
    EMPTY: t('メールアドレスとパスワードの両方を入力してください'),
    EMPTY_NAME: t('名前が必要です'),
    EMPTY_DISPLAY_NAME: t('表示名が必要です'),
    UNKNOWN: t('不明なエラー'),
    // NOTE: language here is required to react to language change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [t, language])

  const displayNameRef = useRef<HTMLInputElement>(null)
  const emailRef = useRef<HTMLInputElement>(null)
  const passwordRef = useRef<HTMLInputElement>(null)

  const [planOptions, setPlanOptions] = useState<Array<TPlan>>([])
  const [selectedPlan, setSelectedPlan] = useState<string | undefined>()
  const [regInfo, setRegInfo] = useState({ email: '', displayName: '', password: '' })

  const [mode, setMode] = useState(SIGN_IN_MODE.SIGN_IN)
  const [loading, setLoading] = useState(false)
  const [signInError, setSignInError] = useState('')

  const [countDown, startCountDown, countDownRef] = useCountDown(60, 'emailReset')

  // support for reseller dedicated user registration page
  const [reseller, setReseller] = useState<GPTechReseller | null>()
  const [sellerPlans, setSellerPlans] = useState<TPlan[]>()
  useEffect(() => {
    // only load reseller info once
    if (reseller !== undefined) {
      return
    }

    const resellerId = _parseQueryParam('reseller', false)
    if (resellerId && !Array.isArray(resellerId)) {
      Api.get(GET_RESELLER_INFO.replace('{id}', resellerId)).then((res) => {
        const { reseller } = res.data
        setReseller(reseller)
        if (reseller) {
          // load corresponding plans for current reseller
          Api.get(GET_RESELLER_PLANS.replace('{id}', resellerId)).then((res) => {
            const { plans } = res.data
            setSellerPlans(plans)
          }).catch((error) => {
            console.warn(error)
          })

          if (mode === SIGN_IN_MODE.SIGN_IN) {
            // automatically switch to dedicated user registration page if there is reseller info
            setMode(SIGN_IN_MODE.REGISTER_1)
          }
        }
      }).catch((error) => {
        console.warn(error)
        // could not load reseller info
        setReseller(null)
      })
    } else {
      // no reseller info
      setReseller(null)
    }
  }, [mode, reseller])

  // load plans when needed
  useEffect(() => {
    Api.get(LIST_OF_AVAILABLE_PLANS).then((response) => {
      if (response.data?.success) {
        // pre-processing
        const plans = (response.data.plans || []).filter((p: TPlan) => !!p.amountJpy || p.productCode === 'FREE')
        setPlanOptions(plans)
      }
    }).catch((error) => {
      // log to sentry
      sentryLog(error)
      console.error('Can not list plans')
    })
  }, [])

  const switchSignIn = useCallback(() => {
    setSignInError('')
    setMode(SIGN_IN_MODE.SIGN_IN)
  }, [])

  const switchRegister = useCallback(() => {
    setSignInError('')
    setMode(SIGN_IN_MODE.REGISTER_1)
  }, [])

  const switchForgotPassword = useCallback(() => {
    setSignInError('')
    setMode(SIGN_IN_MODE.FORGOT_PASSWORD)
  }, [])

  const btnText = useMemo(() => {
    switch (mode) {
      case SIGN_IN_MODE.REGISTER_1:
        return t('プラン選択へ')
      case SIGN_IN_MODE.REGISTER_2:
        return t('登録')
      case SIGN_IN_MODE.FORGOT_PASSWORD:
        return t('送信')
      case SIGN_IN_MODE.SIGN_IN:
      default:
        return t('ログイン')
    }
  }, [mode, t])

  const handleSubmit = useCallback(async (e: any) => {
    e.preventDefault()

    if (mode === SIGN_IN_MODE.SIGN_IN) {
      const email = emailRef.current?.value.trim()
      const password = passwordRef.current?.value.trim()

      if (!email || !password) {
        setSignInError(SIGN_IN_ERROR.EMPTY)
      } else {
        setLoading(true)
        setSignInError('')
        try {
          await UserInfo.logIn(email, password)
        } catch (error) {
          setLoading(false)
          alert(error)
        }
      }
    } else if (mode === SIGN_IN_MODE.FORGOT_PASSWORD) {
      if (countDownRef.current > 0) {
        return
      }

      const email = emailRef.current?.value.trim()

      if (email) {
        setLoading(true)
        UserInfo.forgotPassword(email).finally(() => {
          setLoading(false)
          startCountDown()
        })
      }
    } else if (mode === SIGN_IN_MODE.REGISTER_1) {
      const displayName = displayNameRef.current?.value.trim()
      const email = emailRef.current?.value.trim()
      const password = passwordRef.current?.value.trim()

      if (!email || !password) {
        setSignInError(SIGN_IN_ERROR.EMPTY)
      } else if (!displayName) {
        setSignInError(SIGN_IN_ERROR.EMPTY_DISPLAY_NAME)
      } else {
        if (isAcceptablePassword(password)) {
          setSignInError('')
          setRegInfo({
            email,
            displayName,
            password,
          })
          setMode(SIGN_IN_MODE.REGISTER_2)
        } else {
          setSignInError(SIGN_IN_ERROR.WEAK_PASSWORD)
        }
      }
    } else if (mode === SIGN_IN_MODE.REGISTER_2) {
      const { displayName, email, password } = regInfo

      setLoading(true)
      // setSignInError('')

      // register account
      const success = await UserInfo.register(email, password, displayName, selectedPlan, reseller?.id)
      if (success) {
        switchSignIn()
      }
      setLoading(false)
    }
  }, [mode, SIGN_IN_ERROR, countDownRef, startCountDown, regInfo, selectedPlan, reseller, switchSignIn])

  return (
    <div className='container-fluid p-3' style={{ overflowY: 'auto' }}>
      {process.env.REACT_APP_ENVIRONMENT === 'production' && (
        <div className='text-center text-danger mt-3'>
          <div className='row justify-content-center'>
            <div className='col-auto'>
              【{t('お知らせ')}】{t('2024年4月27日に実施のシステムアップデートに伴い、ログインパスワードの再設定を行いました。')}
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-auto'>
              {t('お手数をお掛けしますが、新しいパスワードをメールにてお送りしておりますので、ご確認の上、ご対応いただけますようお願い申し上げます。')}
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-auto'>
              {t('尚、パスワードは自動生成されたものになりますので、改めてパスワードの変更を行っていただけます。')}
            </div>
          </div>
        </div>
      )}
      <div className='row justify-content-center mt-4 mt-md-5'>
        <div className={`login-box donut-box col p-5 pt-4 col-sm-10${mode !== SIGN_IN_MODE.REGISTER_2 ? ' col-md-8 col-lg-6 col-xl-5' : ''}`}>
          {mode !== SIGN_IN_MODE.SIGN_IN && (
            <div className='row mb-3 align-items-center'>
              <div className='col-auto'>
                <i className='fa-solid fa-chevron-left h3 mb-0'
                  onClick={mode === SIGN_IN_MODE.REGISTER_2 ? switchRegister : switchSignIn} />
              </div>
              <div className='col text-primary text-center h3 mb-0 px-0'>
                <b>
                  {mode === SIGN_IN_MODE.FORGOT_PASSWORD ? (
                    t('パスワード再設定')
                  ) : (
                    !reseller ? t('新規会員登録') : t('新規会員登録・代理店', { reseller: reseller.name })
                  )}
                </b>
              </div>
            </div>
          )}

          <form className={`email-provider-form d-flex flex-column align-items-center${loading ? ' hidden' : ''}`} onSubmit={handleSubmit}>
            <div className='form-group mb-2 mt-4' style={{ width: '100%', display: mode !== SIGN_IN_MODE.REGISTER_2 ? 'block' : 'none' }}>
              <div className='d-flex justify-content-between' style={{ flexWrap: 'wrap' }}>
                <label htmlFor='signin-email' className='text-primary ms-3'>
                  <b>{t('メールアドレス')}</b>
                </label>
              </div>
              <input type='email' className='form-control' id='signin-email'
                ref={emailRef} placeholder='you@email.com' />
            </div>
            {mode !== SIGN_IN_MODE.FORGOT_PASSWORD && (
              <div className='form-group mb-2 mt-4' style={{ width: '100%', display: mode !== SIGN_IN_MODE.REGISTER_2 ? 'block' : 'none' }}>
                <div className='d-flex justify-content-between' style={{ flexWrap: 'wrap' }}>
                  <label htmlFor='signin-password' className='text-primary ms-3'>
                    <b>{t('パスワード')}</b>
                  </label>
                </div>
                <input type='password' className='form-control' id='signin-password'
                  ref={passwordRef} placeholder={'パスワード'} />
                {mode === SIGN_IN_MODE.SIGN_IN && (
                  <label className='text-link text-secondary text-end' style={{ width: '100%' }} onClick={switchForgotPassword}>
                    <small>{t('パスワードを忘れた')}</small>
                  </label>
                )}
              </div>
            )}
            {(mode === SIGN_IN_MODE.REGISTER_1 || mode === SIGN_IN_MODE.REGISTER_2) && (
              <>
                <div className='form-group mt-4 mb-2' style={{ width: '100%', display: mode !== SIGN_IN_MODE.REGISTER_2 ? 'block' : 'none' }}>
                  <label htmlFor='signin-displayname' className='text-primary ms-3'>
                    <b>{t('表示名')}</b>
                  </label>
                  <input className='form-control' id='signin-displayname'
                    ref={displayNameRef} placeholder='表示名' />
                </div>
              </>
            )}
            {mode === SIGN_IN_MODE.REGISTER_2 && (
              // need to wait until reseller info has been loaded
              ((planOptions.length && reseller === null) || (!!reseller && !!sellerPlans)) ? (
                <>
                  <div className='container'>
                    <div className='row'>
                      <div className='col-auto'>
                        {t('メールアドレス')}：
                      </div>
                      <div className='col'>
                        <b>{regInfo.email}</b>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-auto'>
                        {t('表示名')}：
                      </div>
                      <div className='col'>
                        <b>{regInfo.displayName}</b>
                      </div>
                    </div>
                  </div>
                  <PlanSelection plans={reseller ? sellerPlans! : planOptions} onPlanSelect={setSelectedPlan} />
                </>
              ) : (
                <div className='row justify-content-center'>
                  <div className='col-auto py-5'>
                    <div className='spinner-border text-primary' role='status'>
                      <span className='visually-hidden'>Loading...</span>
                    </div>
                  </div>
                </div>
              )
            )}
            {mode === SIGN_IN_MODE.FORGOT_PASSWORD && (
              <small className='mb-1 mt-2 text-center text-secondary d-block'>
                {t('登録したメールアドレスにパスワード再設定用のURLを送ります。')}
              </small>
            )}

            {!!signInError && (
              <small className='text-danger mt-2' style={{ maxWidth: 360 }}>{signInError}</small>
            )}
            <div className='d-flex flex-column align-items-stretch'>
              <button type='submit' disabled={loading || (!!countDown && mode === SIGN_IN_MODE.FORGOT_PASSWORD)}
                className='btn btn-primary bg-grad-primary mt-4 px-5'>
                {/* {mode === SIGN_IN_MODE.SIGN_IN && <i className='fa-solid fa-envelope' />} */}
                {btnText && (
                  (!countDown || mode !== SIGN_IN_MODE.FORGOT_PASSWORD) ? btnText : `${btnText} (${countDown})`
                )}
              </button>
              {mode === SIGN_IN_MODE.SIGN_IN && (
                <button type='button' className='btn btn-outline-primary mt-3' onClick={switchRegister}>
                  {t('新規アカウントを作成')}
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default LoginPage
