import './MeetingItem.css'

import { observer } from 'mobx-react-lite'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import saveAs from 'file-saver'

import CopyToClipboard from '../../view/CopyToClipboard'
import { useCopyToClipboard } from '../../hook/useCopyToClipboard'
import { deleteMeeting, MEETING_STATES, updateMeeting } from '../../api/MeetingFirebaseApi'
import UserInfo from '../../store/UserInfo'
import {
  Meeting,
  RealtimeMinute,
  MINUTE,
  SummaryStatus,
  summaryAgenda,
  initialMinutes,
  initialSummary,
} from '../../store/model/Meeting'
import LoadingButton from '../../view/LoadingButton'
import { beginMeeting, finishMeeting } from '../../api/MeetingApi'
import { ILanguages } from '../../constant/Languages'
import { BUTTON_MODES, SIZE_MODE, show, updateState, hide } from '../../view/PopupEvent'
import { bootstrapQuery, useMediaQuery } from '../../hook/useMediaQuery'
import { className } from '../../util/className'
import { convertDateTimeToStringForView } from "../../util/date";
import { useTranslation } from 'react-i18next'

import RemoveMarkdown from 'remove-markdown'
import ReactMarkdown from "react-markdown";
import rehypeRaw from 'rehype-raw';
import { EditMessage } from "./EditMessage";

const MeetingTitleInput = observer(({ meeting }: { meeting: Meeting }) => {
  const [editable, setEditable] = useState<boolean>(false)
  const [currentTitle, setCurrentTitle] = useState<string>(meeting.title ?? '無題')

  // NOTE(fujii): Invisible Span element to get the title's width from.
  // The width taken will be applied to input field.
  const widthCalculatorRef = useRef<HTMLSpanElement>(null)

  const element = widthCalculatorRef.current
  if (element) {
    element.textContent = currentTitle
  }
  const dynamicWidth = `${Math.max((element?.scrollWidth ?? 200) + 15, 200)}px`

  const onChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentTitle(event.target.value)
  }

  const updateTitle = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    event.stopPropagation()

    if (event.currentTarget.checkValidity()) {
      // Intentionally made it callback to make sure
      // It won't get batched.
      setEditable(_curr => {
        if (meeting.title !== currentTitle) {
          const data = { title: currentTitle }

          updateMeeting(UserInfo.id, meeting.id, data).then(_result => {
            meeting.setData(data)
          })
        }

        return false
      })
    }
  }

  const activateEdit = () => {
    setEditable(true)
    setCurrentTitle(meeting.title)
  }

  const deactivateEdit = () => {
    setEditable(false)
    setCurrentTitle(meeting.title)
  }

  return (
    <form
      className='d-flex flex-nowrap gap-2 align-items-center'
      onSubmit={updateTitle}
      onReset={deactivateEdit}
    >
      {/* NOTE(fujii):
          This span is only used to calculate the input's width
          because the input can't resize themselves.
          aria-hidden=true is required to not confuse screen readers just in case.
        */}
      <span ref={widthCalculatorRef} className='visually-hidden' aria-hidden='true'>{currentTitle}</span>
      <input
        required
        maxLength={32}
        readOnly={!editable}
        // This is required to make font size consistent between form-control and form-control-plaintext
        // TODO(fujii): make it possible to remove this
        style={{fontSize: 'unset', width: dynamicWidth}}
        className='form-control-plaintext'
        value={editable ? currentTitle : meeting.title}
        onChange={onChangeTitle}
      />

      {editable
      ? (
        <>
          <button type='submit' className='btn badge rounded-pill text-success'>
            <i className='fa-fw fa-solid fa-check' />
          </button>
          <button
            type='reset' className='btn badge rounded-pill text-danger'
          >
            <i className='fa-fw fa-solid fa-close' />
          </button>
        </>
      )
      : (
        <button
          type='button'
          className='btn badge rounded-pill text-donut'
          onClick={activateEdit}
        >
          <i className='fa-fw fa-solid fa-pencil' />
        </button>
      )}
    </form>
  )
})

const downloadText = (text: string, fileName = 'meeting minutes.txt') => {
  saveAs(new Blob([text]), fileName)
}

/**
 * Collect messages in specific language for summary,
 * or in all languages if unspecified.
 */
function collectMessageForSummary(meeting: Meeting, lang?: string) {
  const msgs = Meeting.getAllMessages(meeting, lang)

  if (lang) {
    return msgs.map((msg) => `${msg.user}: ${msg.messages[lang]}`).join('<br>')
  } else {
    return msgs.map((msg) => {
      return `${msg.user}:\n${Object.entries(msg.messages).map(([lang, text]) => `${lang}: ${text}`).join('<br>')}<br>`
    }).join('\n')
  }
}

// This is here to avoid copy-pasta.
function openSummaryProcessView(meeting: Meeting, onDelete: (meeting: Meeting) => void) {
  return show({
    title: <MeetingTitleInput meeting={meeting} />,
    content: <SummaryProcessView meeting={meeting} onDelete={onDelete} />,
    size: SIZE_MODE.XLARGE,
    btnMode: BUTTON_MODES.NONE,
  })
}

const MeetingMessage = ({message, onUpdate, onDelete}: {message: { id: string, user: string, messages: { [lang: string]: string }, createdAt?: Date | undefined }, onUpdate: (message_id: string, messages: { [lang: string]: string }) => void, onDelete: (message_id: string) => void}) => {
  const { t } = useTranslation()

  const onEditMessage = (message: { id: string, user: string, messages: { [lang: string]: string }}, lang: ILanguages, text: string) => {
    if(!message.messages[lang]){
      return;
    }
    message.messages[lang] = text;
    onUpdate(message.id, message.messages);
  }

  const onClickDeleteMessage = () => {
    const is_delete = window.confirm(t('メッセージを削除します。よろしいですか？'));
    if(!is_delete){
      return;
    }
    onDelete(message.id);
  }

  const dtFormat = new Intl.DateTimeFormat("ja", {
    dateStyle: "short",
    timeStyle: "short",
  });

  return <div className='mt-2'>
    <span className='text-donut'>{message.user}</span>
    {message.createdAt &&
      <span className='px-2 text-donut'><small>{dtFormat.format(message.createdAt)}</small></span>
    }
    <button className='btn btn-danger btn-sm b-inline-block mt-0 mb-0 me-0 ms-1 pt-0 pb-0'
            style={{verticalAlign: '0', fontSize: '0.75em'}}
            onClick={onClickDeleteMessage}>{t('削除')}</button>
    {Object.entries(message.messages).map(([lang, text]) => (
        <EditMessage
            lang={lang as ILanguages}
            text={text}
            onEdit={(text) => onEditMessage(message, lang as ILanguages, text)} />
    ))}
  </div>
}

const SummaryProcessView = observer(({ meeting, onDelete }: { meeting: Meeting, onDelete: (meeting: Meeting) => void }) => {
  const { t } = useTranslation()

  const messageText = useMemo(() => collectMessageForSummary(meeting), [meeting])
  const [agenda, setAgenda] = useState<string>(summaryAgenda)

  const isOnPc = useMediaQuery(bootstrapQuery.lg)

  const [selectedTab, setSelectedTab] = useState<'minutes' | 'aiSummary'>(
    meeting.summary ? 'aiSummary' : 'minutes'
  )

  const handleMinuteChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedTab('aiSummary');
    setAgenda(e.target.value);
  }

  const handleMinuteChangeMobile = (e: React.MouseEvent<HTMLButtonElement>) => {
    setSelectedTab('aiSummary');
    setAgenda(e.currentTarget.value);
  }

  const handleDownloadClick = () => {
    let text = '';
    if(meeting.realtimeMinutes){
      for (const realtimeMinute of meeting.realtimeMinutes) {
        if(realtimeMinute.markdown !== initialMinutes && realtimeMinute.markdown !== initialSummary){
          text += `【${realtimeMinute.agenda}】\n`;
          text += `${RemoveMarkdown(realtimeMinute.markdown)}\n\n`;
        }
      }
    }
    text += `\n【会話ログ】\n${messageText.trim().replaceAll('<br>', '\n')}`;
    downloadText(text, `${meeting.title}.txt`)
  }

  const handleAgendaDownloadClick = () => {
    let text = '';
    if(meeting.additionalDocument && meeting.additionalDocument !== ''){
      text += meeting.additionalDocument;
    }
    downloadText(text, `${meeting.title}_agenda.txt`)
  }

  const confirmDeleteMeeting = async () => {
    const cancelDeletion = () => openSummaryProcessView(meeting, onDelete)
    const requestDeleteMeeting = () => {
      deleteMeeting(UserInfo.id, meeting.id)
      .then(() => {
        onDelete && onDelete(meeting)
        hide()
      })
    }

    updateState({
      title: t("会議の削除"),
      content: (<div>
          <p>{t('以下の会議を削除します。')}</p>
          <p>{t('削除された会議は元に戻すことができません。よろしいですか？')}</p>
          <div>
            <h5 className='h5'>{meeting.title}</h5>
            <h6 className='text-donut'>{meeting.createdAt}</h6>
          </div>
          <hr />
          <section className='mt-3 d-flex gap-2 justify-content-end'>
            <button onClick={cancelDeletion} className='btn btn-secondary rounded-pill'>
              {t('キャンセル')}
            </button>
            <button onClick={requestDeleteMeeting} className='btn btn-danger rounded-pill'>
              {t('削除する')}
            </button>
          </section>
        </div>
      ),
      size: SIZE_MODE.MEDIUM,
      btnMode: BUTTON_MODES.NONE,
    })
  }

  const onMessageUpdate = (message_id: string, messages: { [lang: string]: string }) => {
    if(!meeting.messages){
      return;
    }
    const index = meeting.messages.findIndex(m => m.id === message_id);
    if(index === -1){
      return;
    }

    const target = meeting.messages[index];
    target.messages = messages;
    meeting.updateMessageText(target);
  }

  const onMessageDelete = (message_id: string) => {
    if(!meeting.messages){
      return;
    }

    const index = meeting.messages.findIndex(m => m.id === message_id);
    if(index === -1){
      return;
    }
    meeting.messages.splice(index, 1);
    meeting.deleteMessage(message_id);
  }

  // 項目名を翻訳する
  const translateMarkdown = (realtimeMinute: RealtimeMinute) => {
    let showTexts = realtimeMinute.markdown;
    if (realtimeMinute.type === MINUTE.agenda && realtimeMinute.markdown !== '' && realtimeMinute.markdown !== initialMinutes) {
      showTexts = '';
      const sections = ['minutes', 'concerns', 'pending', 'decisions', 'next'];
      const translations = ['議事録', '懸念事項', '保留事項', '決定事項', '宿題事項'];

      sections.forEach((section, index) => {
        // @ts-ignore
        if (realtimeMinute[section]) {
          showTexts += `## ${t(translations[index])}\n`;
          // @ts-ignore
          showTexts += `- ${realtimeMinute[section].join('\n- ')}\n`;
        }
      });
    }
    return showTexts;
  }

  return (
      <div className='d-flex flex-column' style={{maxHeight: '70vh'}}>
        {!isOnPc && (
            <ul className='navtab-style-restoration nav nav-tabs mb-2 mt-0'>
              <li className='nav-item dropdown'>
                <button className="nav-link dropdown-toggle" data-bs-toggle="dropdown">{agenda}</button>
                <ul className="dropdown-menu">
                  {meeting.realtimeMinutes && meeting.realtimeMinutes.length !== 0 ? (
                      <>
                        {meeting.realtimeMinutes.map((item, index) => (
                            <li key={item.agenda}>
                              <button value={item.agenda} className="dropdown-item"
                                      onClick={handleMinuteChangeMobile}>{t(item.agenda)}</button>
                            </li>
                        ))}
                      </>
                  ) : (
                      <span className='fs-6'>{t('AIによる要約は行われていません')}</span>
                  )}
                </ul>
              </li>
              <li className='nav-item'>
                <button onClick={() => setSelectedTab('minutes')}
                        className={'nav-link' + (selectedTab === 'minutes' ? ' active' : '')}
                >{t('議事録')}</button>
              </li>
            </ul>
        )}

        <div className='flex-shrink-1 d-flex gap-2 overflow-hidden'>
          {(isOnPc || selectedTab === 'aiSummary') ? (
              <aside className='ai-section d-flex flex-column'>
                {/*
            Summary Title
            */}
                <div className='d-flex section-title gap-1'>
                  {isOnPc &&
                      <h5 className='flex-fill ms-2 mb-0'>
                        {meeting.realtimeMinutes && meeting.realtimeMinutes.length !== 0 ? (
                            <select className="form-select" onChange={handleMinuteChange}>
                              {meeting.realtimeMinutes && meeting.realtimeMinutes.map((item, index) => (
                                  <option key={item.agenda} value={item.agenda}>{t(item.agenda)}</option>
                              ))}
                            </select>
                        ) : (
                            <span className='fs-6'>{t('AIによる要約は行われていません')}</span>
                        )}
                      </h5>
                  }

                  <CopyToClipboard
                      value={meeting.realtimeMinutes?.find(m => m.agenda === agenda)?.markdown ?? ''}
                      disabled={!meeting.realtimeMinutes || meeting.realtimeMinutes.length === 0}
                  />
                </div>
                {isOnPc && <hr/>}
                {/*
            Summary Content
            */}
                <div className='mt-1 flex-fill flex-grow-1 overflow-hidden d-flex'>
                  <div className='overflow-y-auto flex-fill p-1'>
                    <div className='summary-bubble p-2'>
                      {(!meeting.realtimeMinutes || meeting.realtimeMinutes.length === 0) &&
                          <div>
                            <p className='text-donut'>
                              {t('AIによる要約は行われていません')}
                            </p>
                          </div>
                      }
                      {meeting.realtimeMinutes && meeting.realtimeMinutes.map((item, index) => (
                          <>
                            {agenda === item.agenda && (<ReactMarkdown
                                rehypePlugins={[rehypeRaw]}>{item.markdown === initialSummary ? t('AIによる要約は行われていません') : translateMarkdown(item)}</ReactMarkdown>)}
                          </>
                      ))}
                    </div>
                  </div>
                </div>
              </aside>
          ) : null}
          {(isOnPc || selectedTab === 'minutes') ? (
              <section className='minutes-section d-flex flex-column'>
                {/*
            Minutes Title
            */}
                <div className='d-flex section-title'>
                  {isOnPc && (
                      <h5 className='flex-fill mb-0'>
                        {t('議事録')}
                      </h5>
                  )}
                  <CopyToClipboard
                      value={`[議事録]:\n${messageText}`}
                      disabled={!messageText}
                  />
                </div>

                {isOnPc && <hr/>}

                {/*
            Minutes Section
            */}
                <div className='mt-1 flex-fill flex-grow-1 overflow-hidden d-flex'>
                  <div className='overflow-y-auto flex-fill'>
                    {(meeting.messages && meeting.messages.length > 0)
                        ? Meeting.getAllMessages(meeting).map((message: { id: string, user: string, messages: { [lang: string]: string }}) => (
                            <MeetingMessage key={message.id} message={message} onUpdate={onMessageUpdate} onDelete={onMessageDelete} />
                        ))
                        : <div className='p-2 text-donut'>
                          {t('議事録の中身がありません…')}
                        </div>
                    }
                  </div>
                </div>
              </section>
          ) : null}

        </div>
        <hr/>
        {/*
        Footer Section
      */}
        <section className='flex-shrink-1 d-flex gap-2 justify-content-between'>
          <button
              onClick={confirmDeleteMeeting}
              disabled={meeting.summaryStatus === SummaryStatus.PROCESSING}
              className='btn btn-danger rounded-pill'
          >
            {t('この会議を削除')}
          </button>

          <div>
            {(meeting.additionalDocument && meeting.additionalDocument !== '') && (
                <button
                    onClick={handleAgendaDownloadClick}
                    className='btn btn-success rounded-pill me-3'
                >
                  {t('アジェンダのダウンロード')}
                </button>
            )}
            <LoadingButton
                className='rounded-pill'
                onClick={handleDownloadClick}
                disabled={meeting.summaryStatus !== SummaryStatus.READY}
                isLoading={meeting.summaryStatus === SummaryStatus.PROCESSING}
            >
              {(meeting.summaryStatus === SummaryStatus.PROCESSING)
                  ? t('処理中…')
                  : t('ダウンロード')
              }
            </LoadingButton>
          </div>
        </section>
      </div>
  )
})


const MeetingText = observer(({meeting}: { meeting: Meeting }) => {
  let summary = meeting.summary
  if (meeting.realtimeMinutes && meeting.realtimeMinutes.length !== 0) {
    const index = meeting.realtimeMinutes.findIndex(r => r.type === MINUTE.summary);
    if (index !== -1) {
      summary = meeting.realtimeMinutes[index].markdown
    }
  }
  const messages = meeting.messages
  const {t} = useTranslation()

  const renderText = useMemo(() => {
    if (summary) {
      return summary === initialSummary ? t('AIによる要約は行われていません') : summary;
    } else if (messages) {
      return collectMessageForSummary(meeting, 'ja-JP')
    } else {
      return ''
    }
  }, [summary, messages, meeting, t])

  return (meeting.state === 'completed' ? (
      <div className='meeting-minutes thumb mb-3'>
        {renderText ? <ReactMarkdown rehypePlugins={[rehypeRaw]}>{renderText}</ReactMarkdown> : <p className='text-donut p-3 text-center'>{t('議事録の中身がありません…')}</p>}
      </div>
    ) : null)
})

const MeetingDuration = observer(({ meeting }: { meeting: Meeting }) => {
  /*
   * State Management Part:
   * Only create an interval when the meeting state is in-call.
   */
  const [dynTime, setDynTime] = useState<number>(0) // Only used when state === incall.
  const { t } = useTranslation()
  const state = meeting.state

  useEffect(() => {
    let handle: NodeJS.Timer | null = null

    if (state === 'incall') {
      handle = setInterval(() => {
        if (meeting.beginAt != null) {
          const nowInSec = Date.now()
          setDynTime((nowInSec - meeting.beginAt.getTime()) / 1000)
        }
      }, 1000)
    }

    return () => {
      if (handle) {
        clearInterval(handle)
      }
    }
  }, [state, meeting])

  /*
   * Rendering Part:
   */
  const MINUTES = 60
  const HOURS   = 60 * MINUTES

  let timeElapsed: number | null = null

  if (meeting.state === 'waiting') {
    timeElapsed = meeting.allocatedTime || null
  } else if (meeting.state === 'incall') {
    timeElapsed = dynTime
  } else if (meeting.state === 'completed') {
    timeElapsed = meeting.timeElapsed
  }

  if (timeElapsed != null) {
    const hoursElapsed = Math.floor(timeElapsed / HOURS)
    const minutesElapsed = (timeElapsed % HOURS) > MINUTES ? Math.floor((timeElapsed % HOURS) / MINUTES) : 0

    const klass = className('bi', {
      'bi-clock': meeting.state === 'incall' || meeting.state === 'completed',
      'bi-clock-fill': meeting.state === 'waiting',
    })

    return <span>
      <i className={klass} />
      {hoursElapsed > 0 ? t('time.hour', { count: hoursElapsed }) : ''} {t('time.minute', { count: minutesElapsed })}
    </span>
  }

  return null
})


export const MeetingItem = observer(({ meeting, onDelete }: { meeting: Meeting, onDelete: (meeting: Meeting) => void }) => {
  const [loading, setLoading] = useState(false)
  const { state, messages } = meeting
  const { t } = useTranslation()

  useEffect(() => {
    if (state === 'completed') {
      if (!messages) {
        meeting.fetchMessages(false)
      }
    }
  }, [meeting, state, messages])

  const meetingURL = `${process.env.REACT_APP_MEETING_URL || ''}?t=${meeting.token}`
  const [isCopyingURL, copyMeetingURL] = useCopyToClipboard(meetingURL)

  const handleFinish = useCallback(async () => {
    const ok = await show({
      title: t('会議の終了'),
      content: (<div>
        <p>{t('以下の会議を終了します。よろしいですか？')}</p>
        <div>
          <h5 className='h5'>{meeting.title}</h5>
          <h6 className='text-donut'>{meeting.createdAt}</h6>
        </div>
      </div>),
      okText: t('終了する')
    })

    if (ok) {
      try {
        setLoading(true)
        // await updateMeeting(UserInfo.id, meeting.id, { state: MEETING_STATES.COMPLETED })
        const response = await finishMeeting(meeting.id)
        if (response.data.success) {
          if (meeting.beginAt && meeting.allocatedTime) {
            await UserInfo.refreshTime()
          }
          meeting.setData({ state: MEETING_STATES.COMPLETED })
        } else {
          alert(t('不明なエラー'))
        }
      } finally {
        setLoading(false)
      }
    }
  }, [meeting, t])

  const handleStart = useCallback(async () => {
    const ok = await show({
      title: t('会議の開始'),
      content: (<div>
        <p>{t('以下の会議が開始されます。よろしいですか？')}</p>
        <div>
          <h5 className='h5'>{meeting.title}</h5>
          <h6 className='text-donut'>{meeting.scheduledBeginAt ? `${t('開始予定時刻')}: ${convertDateTimeToStringForView(meeting.scheduledBeginAt)}` : meeting.createdAt}</h6>
        </div>
      </div>),
      okText: t('開始する')
    })

    if (ok) {
      try {
        setLoading(true)
        // await updateMeeting(UserInfo.id, meeting.id, { state: MEETING_STATES.COMPLETED })
        const response = await beginMeeting(meeting.id)
        if (response.data.success) {
          meeting.setData({ beginAt: new Date(), state: MEETING_STATES.INCALL })
          window.open(meetingURL, "_blank")?.focus();
        } else {
          alert(t('不明なエラー'))
        }
      } finally {
        setLoading(false)
      }
    }
  }, [meeting, meetingURL, t])

  const openSummary = async () => {
    if (meeting.state === 'completed') {
      await meeting.fetchMessages(true)
      openSummaryProcessView(meeting, onDelete)
    }
  }
  return (
    <div key={meeting.id} className={`col-auto mb-4 ${loading ? 'disabled' : ''}`}>
      <div
        className={`meeting-item d-flex flex-column justify-content-between ${meeting.state === 'completed' ? 'finished' : ''}`}
        onClick={openSummary}
      >
        <div className='d-flex flex-column mb-3'>
          <div className='d-flex align-items-center justify-content-between'>
            <small>{(meeting.beginAt && convertDateTimeToStringForView(meeting.beginAt)) || (meeting.scheduledBeginAt && convertDateTimeToStringForView(meeting.scheduledBeginAt)) || meeting.createdAt}</small>
          </div>
          <h4 className='meeting-item-title pb-1 mb-2'>{meeting.title}</h4>
            <div className='d-flex gap-2'>
              {!!meeting.users?.length && (
                <span>
                  <i className='bi bi-people-fill' /> {meeting.users.length}
                </span>
              )}
              <MeetingDuration
                meeting={meeting} />
            </div>
        </div>
        <MeetingText
          meeting={meeting} />
        <div className='d-flex flex-column align-items-center gap-3'>
          {meeting.state === 'waiting' && (
            <>
              <button onClick={copyMeetingURL}
                className={`btn rounded-pill ${isCopyingURL ? 'btn-success' : 'btn-outline-primary'}`}>
                {isCopyingURL ? t('コピーしました！') : t('招待リンクのコピー')}
              </button>
              <button onClick={handleStart}
                className='btn btn-success rounded-pill'>
                {t('会議を開始する')}
              </button>
              <button onClick={handleFinish}
                className='btn btn-primary rounded-pill'>
                {t('会議を終了する')}
              </button>
            </>
          )}
          {meeting.state === 'incall' && (
            <>
              <button onClick={copyMeetingURL}
                className={`btn rounded-pill ${isCopyingURL ? 'btn-success' : 'btn-outline-primary'}`}>
                {isCopyingURL ? t('コピーしました！') : t('招待リンクのコピー')}
              </button>
              <button onClick={handleFinish} className='btn btn-primary rounded-pill'>
                {t('会議を終了する')}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  )
})
